import { useProjects } from "contexts/projectsContext";
import { useReferenceType } from "hooks/useReferenceType.hook";
import React from "react";
import { useTranslation } from 'react-i18next';

interface NAME_ROUTES_INTERFACE {
    [key: string]: string;
}

const ARTICLES = 'Articles';

const NAMES_ROUTES: NAME_ROUTES_INTERFACE = {
    "metrics": "sidebar-nav-metrics",
    "sustainabilityindex": "sidebar-nav-index",
    "sustainabilityindex-dashboard": "sidebar-nav-articles",
    "sustainabilityindex-articles": "sidebar-nav-articles",
    "traceability": "sidebar-nav-traceability",
    "lca": "product-selector-lca",
    "lca-dashboard": "sidebar-nav-articles",
    "lca-articles": "sidebar-nav-articles",
    "benchmark": "selector-benchmark-articles",
    "benchmark-articles": "sidebar-nav-articles",
    "digitization": "sidebar-nav-digitization",
    "ecommerce": " ",
    "ecommerce-integration": "integration-header",
    "ecommerce-customization": "customization-header",
    "smartlabels": "sidebar-nav-smartlabels",
    "smartlabels-articles": "sidebar-nav-articles",
    "plm": "PLM",
    "plm-dashboard": "project-dashboard",
    "plm-articles": "sidebar-nav-articles",
    "plm-suppliers": "suppliersTraceability",
    "plm-materials": "sidebar-nav-materials",
    "data-collection": "data-collection",
    "data-collection-projects": "sidebar-nav-projects",
    "cei": "cei",
    "cei-dashboard": "sidebar-nav-articles",
    "cei-articles": "sidebar-nav-articles",
    "library": "sidebar-nav-library",
}
export const Breadcrumb = () => {
    const { t } = useTranslation();
    const wPath = window.location.pathname;
	const generalProduct = wPath.split('/')[1];
	const product = wPath.split('/')[2];
	const scope = wPath.split('/')[3];
    const { projectSelected } = useProjects();
    const { validateReferenceType } = useReferenceType(projectSelected);

    return (
        <div className="d-flex flex-row ml-3 mb-1 text-dark-60 font-12px" style={{minWidth: '300px'}}>
            {
                NAMES_ROUTES[generalProduct] &&
                <>
                    <div className="mx-1 font-12px">{ t(NAMES_ROUTES[generalProduct]) }</div>
                    {
                        NAMES_ROUTES[product] && NAMES_ROUTES[product] !== ' ' &&
                        <span>&#124;</span>
                    }
                </>
            }
            {
                NAMES_ROUTES[product] &&
                <>
                    {
                        NAMES_ROUTES[product] !== ' ' &&
                        <div className="mx-1 font-12px">{ t(NAMES_ROUTES[product]) }</div>
                    }
                    {
                        NAMES_ROUTES[`${product}-${scope}`] &&
                        <span>&#124;</span>
                    }
                </>
            }
            { NAMES_ROUTES[`${product}-${scope}`] && <div className="mx-1 font-12px">{ (validateReferenceType && t(NAMES_ROUTES[`${product}-${scope}`]) === ARTICLES) ? t('materials') : t(NAMES_ROUTES[`${product}-${scope}`]) }</div> }
        </div>
    )
}