import React, { useEffect, useState } from 'react';
import { Carousel } from 'UI/molecules/Carousel/Index';

import { DataType, libraryLoader, LibraryResource } from 'loader/library-loader';
import { LayoutLibrary } from './LayoutLibrary';
import { filtersLibrary } from '../../../constants';
import { useTranslation } from 'react-i18next';

import LibraryHttp from 'services/http/Library';

function Library() {
	const library = new LibraryHttp();
    const { t } = useTranslation();
	const [filterSelected, setFilterSelected] = useState({
		documents: filtersLibrary[0],
		resources: filtersLibrary[0],
	});
	const [searchText, setSearchText] = useState({
		documents: '',
		resources: '',
	});

	const [data, setData] = useState<Record<string, LibraryResource[]>>({
		documents: [],
		resources: [],
	});

	const handleFilter = (key: string, value: string) =>
		setFilterSelected({ ...filterSelected, [key]: value });
	const handleSearch = (key: string, value: string) =>
		setSearchText({ ...searchText, [key]: value });

	useEffect(() => {
		library
		.getAll()
		.then((res) => {
			res.data.forEach((element: LibraryResource) => {
				if (element.section === DataType.documents) {
					setData((data) => ({ ...data, documents: [...data.documents, element] }));
				} else {
					setData((data) => ({ ...data, resources: [...data.resources, element] }));
				}
			})
		})
	}, [])

	const LibraryDocuments = libraryLoader(
		Carousel,
		DataType.documents,
		filterSelected.documents,
		searchText.documents,
		data.documents
	);
	const LibraryResources = libraryLoader(
		Carousel,
		DataType.resources,
		filterSelected.resources,
		searchText.resources,
		data.resources
	);

	return (
		<section className="box-shadow m-2 p-1 bg-white" style={{ height: '97%', overflowY: 'auto', borderRadius: '2px' }}>
			<LayoutLibrary
				keyName="documents"
				title={t('documents-title')}
				filters={filtersLibrary}
				filterSelected={filterSelected.documents}
				handleFilter={handleFilter}
                searchText={searchText.documents}
                handleSearch={handleSearch}
			>
				<LibraryDocuments />
			</LayoutLibrary>
			<hr />
			<LayoutLibrary
				keyName="resources"
				title={t('resources-title')}
				filters={filtersLibrary}
				filterSelected={filterSelected.resources}
				handleFilter={handleFilter}
                searchText={searchText.resources}
                handleSearch={handleSearch}
			>
				<LibraryResources />
			</LayoutLibrary>
		</section>
	);
}

export default Library;
