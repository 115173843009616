/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalLateral } from 'UI/organism/ModalLateral/Index';
import { IntegrationDivisor, IntegrationItem } from './IntegrationItem';
import { BlockIntegration } from './BlockIntegration';
import { useAccount } from 'contexts/accountContext';
import { ApiIntegration } from './ApiIntegration';
import { useMenuState } from 'hooks/useMenuState.hook';

export const EcommerceIntegration = (props: any) => {
	const { callToAction } = useMenuState('integration');
	const { t } = useTranslation();
	const { history } = props;
	const [showModal, setShowModal] = useState(false);
	const { account } = useAccount();

	const images = [
		{
			src: '../img/integration-widget.png',
			alt: 'integration-skfk',
		},
		{
			src: '../img/integration-api.png',
			alt: 'integration',
		},
	];

	interface ImgDiv {
		src: string;
		alt: string;
	}

	const ImgDiv = ({ src, alt }: ImgDiv) => {
		return (
			<div className="py-3 px-2">
				<img src={src} alt={alt} style={{ width: '230px' }}></img>
			</div>
		);
	};

	const handleWidgetIntegration = () => {
		history.push('/digitization/ecommerce/customization');
	};

	const handleCloseModal = (e: any, close?: boolean) => {
		if (close || !e.target.closest('.modal-div')) {
			setShowModal(false);
		}
	};

	return (
		<div
			className="d-flex flex-column h-100 rounded-10 px-1"
			style={{ paddingBottom: '10px' }}
		>
			<div
				className="d-flex flex-column flex-grow-1 rounded-10 overflow-auto integration-container"
				style={{
					paddingBottom: '10px',
				}}
			>
				<IntegrationItem
					photo={images[1].src}
					title={t('integration-api-title')}
					description={t('integration-api-subtitle')}
					linkText={t('integration-api-link')}
					linkUri="https://bcome.biz/help-center/bcome-for-developers/how-to-integrate-via-api-platform/"
					buttonText={t('integration-api-button')}
					onClick={() => setShowModal(true)}
				/>
				<IntegrationDivisor />
				<IntegrationItem
					photo={images[0].src}
					title={t('integration-ecommerce-title')}
					description={t('integration-ecommerce-subtitle')}
					linkText={t('integration-ecommerce-link')}
					linkUri="https://bcome.biz/help-center/tutorials/how-to-integrate-the-e-commerce-in-your-website/"
					buttonText={t('integration-ecommerce-button')}
					onClick={handleWidgetIntegration}
				/>
			</div>
			{showModal && (
				<ModalLateral
					handleClose={(e: any) => handleCloseModal(e)}
					style={{ minWidth: '500px' }}
				>
					{!account.apiIntegration || callToAction ? (
						<BlockIntegration handleCloseModal={handleCloseModal} />
					) : (
						<ApiIntegration handleCloseModal={handleCloseModal} />
					)}
				</ModalLateral>
			)}
		</div>
	);
};

export default EcommerceIntegration;
