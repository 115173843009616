/* eslint-disable */
import React from 'react';
import PageLoader from 'components/Common/Loader/Loader';

import { useHistory } from 'react-router-dom';
import PageList from 'pages/PageList/PageList';
import { APP_ENTITY_NAMES, APP_MODALS } from '../../../constants';
import PROJECTS_SCHEMA from '../../../components/Table/schemas/projects.schema';
import ProjectResultTableHeader from 'pages/ProjectResult/ProjectResultTableHeader';
import ProjectResultListHeader from 'pages/ProjectResult/ProjectResultListHeader';
import ListItem from '../../../components/Table/components/TableItem';

import PATHS from 'permissions/roles/AppPaths';
import ChartContainer from 'UI/Template/ChartContainer';

import { CustomIcon } from 'UI/atoms/CustomIcon/Index';

import useHome from 'hooks/useHome.hook';
import { FormHudspot } from 'UI/molecules/FormHudspot';
import { AccountFeatureKeysProps } from 'hooks/useMenuState.hook';
import { SmartLabelTooltip } from '../Smartlabel/SmartLabelTooltip';

const OLD_HOME = '/home';

const Home = (props: any) => {
	const history = useHistory();
	const h = history || props.history;

	if (h.location.pathname === OLD_HOME) {
		h.push(PATHS.home);
	}

	const {
		handleDates,
		handleClasses,
		onGetItems,
		allProjects,
		loading,
		t,
		account
	} = useHome();

	let ret = <PageLoader />;

	const onClickItem = (item: any) => {

	}

	const toggleCalculateReferenceAvailable = () => {
		if (account) return account.totalReferences - account.refUsed;

		return 0;
	}

	if (!loading) {
		ret = (
            <div className="pb-4" style={{height: "100%", overflow:"auto"}}>
				<div className='d-flex flex-wrap mb-3'>
					<div className="col p-0 m-2" style={{ minWidth: '280px', height: '160px' }}>
						<ChartContainer
							title={t('home-projects')}
							// padding={3}
						>
							<div
								className="d-flex mx-auto font-sfpro-regular text-black-100"
								style={{ fontSize: '40px' }}
							>
								{allProjects?.length > 0 ? allProjects.length : 0}
							</div>
						</ChartContainer>
					</div>
					<div className="col p-0 m-2" style={{ minWidth: '280px', height: '160px' }}>
						<ChartContainer
							title={t('home-references')}
							// padding={3}
						>
							<div
								className="d-flex mx-auto font-sfpro-regular text-black-100 font-40px"
								style={{ fontSize: '40px' }}
							>
								{account ? account?.historicalReference : 0}
							</div>
						</ChartContainer>
					</div>
					<div className="col p-0 m-2" style={{ minWidth: '280px', height: '160px' }}>
						<ChartContainer
							title={
								<>
									<span>{t('home-custom-dataset')}</span>
									<SmartLabelTooltip
										label='home-custom-dataset'
										text={t('home-custom-dataset-tooltip')}
									></SmartLabelTooltip>
								</>
							}
							// padding={3}
						>
							<div className="d-flex mx-auto flex-row justify-content-between align-items-center w-80">
								<FormHudspot
									buttonText={t('home-custom-dataset-buttom')}
									nameFormId='REACT_APP_HUBSPOT_FORM_CUSTOM_DATASET'
									styles={{ width: 'max-content' }}
								/>
							</div>
						</ChartContainer>
					</div>
				</div>
				{/* Projects list */}
				<div className='d-flex flex-wrap'>
					<div className={`row m-2 rounded-02 bg-white-100 position-relative w-49 p-2`} style={handleClasses(allProjects)}>
						{allProjects && <PageList
							initialFilter={{}}
							entityName={APP_ENTITY_NAMES.projects}
							onGetItems={onGetItems}
							schema={PROJECTS_SCHEMA}
							ListHeader={() => {
								return <ProjectResultTableHeader removeBorderBottom={true} title={"Project list"} />;
							}}
							EntityFilter={ProjectResultListHeader}
							ListItem={ListItem}
							contentWrapperClass="border-0"
							onClickListItem={onClickItem}
							modalCreate={APP_MODALS.modalNewProject}
						/>}
					</div>
					<div className="col p-0 m-2 w-49" style={{ maxHeight: '347px', minHeight: '347px' }}>
						<ChartContainer
							title={t('Contract Status')}
							between
							// padding={3}
						>
							<div className='d-flex flex-row w-100 align-items-end h-100'>
								<div className='d-flex flex-column' style={{ width: '25%' }}>
									{
										(typeof account?.refAvailable !== 'number' && !account?.refAvailable)
										? 	<img
												src="/img/available-reference.svg"
												alt=" "
												aria-hidden="true"
												style={{
													width: '35px',
													height: '35px'
												}}
											/>
										: 	<span className='text-dark-100 font-sfpro-regular' style={{ fontSize: '40px' }}>{ toggleCalculateReferenceAvailable() }</span>
									}
									<span className='text-dark-40 mt-2'>{ t('home-available-reference') }</span>
								</div>
								<div className='ml-5 d-flex flex-column pr-2 justify-content-end mt-5 font-14px' style={{ width: '70%' }}>
									<div className='d-flex flex-row justify-content-start mb-2' style={{gap:7}}>
										<span className='text-dark-40'>{ t("account-features")}:</span>
										<span className='text-dark-60 font-sfpro-regular'>{ account?.accountFeatureKeys?.map((feature: AccountFeatureKeysProps) => feature.name).join(", ") }</span>
									</div>
									<div className='d-flex flex-row justify-content-start' style={{gap:7}}>
										<span className='text-dark-40'>Expiring date:</span>
										<span className='text-dark-60 font-sfpro-regular'>{account ? handleDates(account.expiration) : "--"}</span>
									</div>
								</div>
							</div>
						</ChartContainer>
					</div>
				</div>

				{/* Dashboards section */}
				<div className='d-flex flex-wrap mb-4' style={{height: 160}}>
					<a className="col p-0 m-2" style={{ minWidth: '200px'}} target="_blank" rel="noopener noreferrer" href="https://bcome.biz/help-center/">
						<ChartContainer
							title={
								<div className="text-overflow-ellipsis overflow-hidden">
									<span>{t('home-item-1')}</span>
									<span className="icon-icon-new-window ml-2"></span>
								</div>
							}
							// padding={3}
						>
							<CustomIcon name='bcome-methodologies' size={"100%"} />
						</ChartContainer>
					</a>
					<a className="col p-0 m-2" style={{ minWidth: '200px'}} target="_blank" rel="noopener noreferrer" href="https://bcome.biz/help-center/methodologies-versions/product-methodologies/">
						<ChartContainer
							title={
								<div className="text-overflow-ellipsis overflow-hidden">
									<span>{t('home-item-2')}</span>
									<span className="icon-icon-new-window ml-2"></span>
								</div>
							}
							padding={4}
						>
							<CustomIcon name='bcome-knowledge' size={"100%"} />
						</ChartContainer>
					</a>
					<a className="col p-0 m-2" style={{ minWidth: '200px'}} target="_blank" rel="noopener noreferrer" href="https://bcome.biz/blog/">
						<ChartContainer
							title={
								<div className="text-overflow-ellipsis overflow-hidden">
									<span>{t('home-item-3')}</span>
									<span className="icon-icon-new-window ml-2"></span>
								</div>
							}
							padding={4}
						>
							<CustomIcon name='bcome-what-next' size={"100%"} />
						</ChartContainer>
					</a>
					<a className="col p-0 m-2" style={{ minWidth: '200px'}}>
						<ChartContainer
							title={
								<div className="text-overflow-ellipsis overflow-hidden">
									<span>{t('home-item-4')}</span>
								</div>
							}
							padding={4}
						>
							<FormHudspot
								buttonText='Request compliance advice'
								nameFormId='REACT_APP_HUBSPOT_FORM_LEGISLATION_MODULE'
							/>
							<CustomIcon name='bcome-blog' size={"100%"} />
						</ChartContainer>
					</a>
				</div>
			</div>
		);
	}
	return ret;
};

export default Home;

const NoDataToDisplay = () => (
	<div
		className="d-flex align-items-center justify-content-center text-dark-60 font-12px"
	>
		There's no data to display
	</div>
);
