/* eslint-disable @typescript-eslint/no-empty-function */
import Axios from "axios";

import StorageAuth from "./../services/storage/Auth";
import Auth from "./http/Auth";
import Notification from "../components/Elements/Notification";
import AppPaths from "permissions/roles/AppPaths";

const SAuth = new StorageAuth();
const auth = new Auth();

class TokenManager {
    private static instance: TokenManager;
    private refreshTimeout: NodeJS.Timeout | null = null;
    private isRefreshing = false;
    private failedAttempts = 0;
    private MAX_RETRY_ATTEMPTS = 3;

    /** Private constructor to prevent direct instantiation as part of Singleton pattern */
    private constructor() {}

    static getInstance(): TokenManager {
        if (!TokenManager.instance) {
            TokenManager.instance = new TokenManager();
        }
        return TokenManager.instance;
    }

    // Función para decodificar el token JWT
    private decodeToken(token: string): any {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            return JSON.parse(window.atob(base64));
        } catch (error) {
            return null;
        }
    }

    // Calcula el tiempo hasta la expiración del token en milisegundos
    private getTimeUntilExpiration(token: string): number {
        const decodedToken = this.decodeToken(token);
        if (!decodedToken) return 0;
        const expirationTime = decodedToken.exp * 1000;
        return expirationTime - Date.now();
    }

    // Programa la próxima renovación del token
    private scheduleTokenRefresh(token: string) {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }

        const timeUntilExp = this.getTimeUntilExpiration(token);
        if (timeUntilExp <= 0) {
            this.refreshToken();
            return;
        }

        // Programar la renovación 5 minutos antes de la expiración
        const refreshDelay = Math.max(0, timeUntilExp - 5 * 60 * 1000);
        this.refreshTimeout = setTimeout(() => this.refreshToken(), refreshDelay);
    }

    // Renueva el token
    private async refreshToken() {
        if (this.isRefreshing) return;

        const session = SAuth.getSession();
        if (!session.accessToken || !session.refreshToken) {
            this.stopTokenRefresh();
            return;
        }

        this.isRefreshing = true;

        try {
            const refreshedSession = await auth.refreshToken(session.refreshToken);
            this.failedAttempts = 0;
            
            SAuth.saveSession({
                accessToken: refreshedSession.accessToken,
                refreshToken: session.refreshToken,
                rememberMe: session.rememberMe || false,
            });

            // Programar la próxima renovación
            this.scheduleTokenRefresh(refreshedSession.accessToken);
        } catch (error) {
            this.failedAttempts++;
            
            if (this.failedAttempts >= this.MAX_RETRY_ATTEMPTS) {
                Notification.display(
                    "error",
                    "Unable to maintain your session. Please log in again."
                );
                this.logout();
            } else {
                const retryDelay = 30000;
                setTimeout(() => this.refreshToken(), retryDelay);
                if (this.failedAttempts > 1) {
                    Notification.display(
                        "warning",
                        "Having trouble maintaining your session. Retrying..."
                    );
                }
            }
        } finally {
            this.isRefreshing = false;
        }
    }

    // Iniciar el manejo de tokens
    startTokenRefresh() {
        const session = SAuth.getSession();
        if (session.accessToken) {
            this.scheduleTokenRefresh(session.accessToken);
        }
    }

    // Detener el manejo de tokens
    stopTokenRefresh() {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
            this.refreshTimeout = null;
        }
        this.isRefreshing = false;
        this.failedAttempts = 0;
    }

    // Función de logout
    private logout() {
        this.stopTokenRefresh();
        SAuth.removeSession();
        window.location.href = AppPaths.login;
    }
}

// Instancia del TokenManager
const tokenManager = TokenManager.getInstance();

export default () => {
    // Iniciar el manejo de tokens si hay una sesión activa
    tokenManager.startTokenRefresh();

    Axios.interceptors.request.use(async (config) => {
        const token = SAuth.getSession().accessToken;
        if (token) {
            let t = token;
            if (t.startsWith('Bearer') == false) {
                t = `Bearer ${token}`
            }
            config.headers && (config.headers.Authorization = `${t}`);
        }
        return config;
    });

    Axios.interceptors.response.use(undefined, async (error) => {
        if (error.response?.data?.statusCode === 403) {
            const Session = SAuth.getSession();

            if (!Session.refreshToken || error.response.data.path === "/api/auth/refresh") {
                tokenManager.stopTokenRefresh(); // Detener el manejo de tokens
                SAuth.removeSession();
                Notification.display(
                    "warning",
                    "Your session has expired. Please log in again."
                );
                window.location.href = AppPaths.login;
                throw new Axios.Cancel();
            }

            try {
                const refreshedSession = await auth.refreshToken(Session.refreshToken);
                SAuth.saveSession({
                    accessToken: refreshedSession.accessToken,
                    refreshToken: Session.refreshToken,
                    rememberMe: Session.rememberMe || false,
                });
                tokenManager.startTokenRefresh(); // Reiniciar el manejo de tokens
                return Axios.request(error.config);
            } catch (err) {
                tokenManager.stopTokenRefresh(); // Detener el manejo de tokens
                SAuth.removeSession();
                Notification.display(
                    "error",
                    "Could not update token. Please log in again."
                );
                window.location.href = AppPaths.login;
                throw new Axios.Cancel();
            }
        }

        throw error;
    });
};