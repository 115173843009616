import React, { useEffect, useState } from "react";
import TableHeaderOptions from "UI/organism/TableHeaderOptions";
import TableHeader from "UI/organism/TableHeader";
import TableBody from "UI/organism/TableBody";
import TableFooter from "UI/organism/TableFooter";
import "./Table.scss";
import { useTable } from 'contexts/tableContext';
import { useTranslation } from "react-i18next";
import NoViewsBackground from "UI/molecules/NoViewsBackground";
import { SpinnerLoader } from "components/Common/Loader/Loader";

import { tableLoader } from "loader/table-loader";

interface Props {
    isLoading: boolean,
    items: any[]
}

const TableMetrics = ({ items, isLoading }: Props) => {
    const { t } = useTranslation();
    const { selectedView, areThereViews } = useTable();

    const [isArtNotFoundMsg, setIsArtNotFoundMsg] = useState(false)

    const artNotFoundMsg = (
        <div className="msg-art-not-found text-dark-100 font-sfpro-regular font-14px">
            <p>{t('common-no-articles-found')}</p>
        </div>
    )

    useEffect(()=> {
        setTimeout(()=> {
            if (items.length === 0) setIsArtNotFoundMsg(true)
            else setIsArtNotFoundMsg(false)
        },100)
    },[items])

    return (
        <div className="table-footer-container d-flex flex-column h-100 rounded-02" style={{ paddingBottom: '10px'}}>
            <div key={selectedView?.group} className="d-flex flex-column overflow-hidden flex-grow-1 bg-white-100 rounded-02 ">
                <TableHeaderOptions isLoading={isLoading}/>
                <div className="table-v1-3 d-flex flex-column border-bottom">
                    {isLoading &&
                    <div className="m-auto">
                        <SpinnerLoader color={"#1C1C30"} width={"60px"} height={"60px"}/>
                    </div>
                    }
                    {!isLoading && (areThereViews == "yes" || selectedView) &&
                        <table>
                            <TableHeader key={selectedView?.id} />
                            <TableBody items={items} />
                        </table>
                    }
                    {!isLoading && (areThereViews == "yes" || selectedView) && isArtNotFoundMsg && artNotFoundMsg}
                    {!isLoading && areThereViews === "no" && !selectedView && <NoViewsBackground />}
                </div>
            <TableFooter />
            </div>
        </div>
    )
}

export default tableLoader(TableMetrics);